import React, { Component, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";
import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton,
  } from "react-share";

// import Assets
// import Loker from '../Assets/Images/loker.png';
import IconFB from '../Assets/Images/fb2.svg';
import IconIG from '../Assets/Images/ig2.svg';
import IconWA from '../Assets/Images/wa.svg';
import IconTelegram from '../Assets/Images/telegram.svg';
import IconLine from '../Assets/Images/line.svg';
import IconEmail from '../Assets/Images/email.svg';
import IconLinkedIn from '../Assets/Images/linkedin.svg';
import IconCalendar from '../Assets/Images/calendar-check.svg';
import IconBankNote from '../Assets/Images/bank-note.svg';
import IconClock from '../Assets/Images/clock-check.svg';
import IconFile from '../Assets/Images/file-attachment.svg';
import IconMarker from '../Assets/Images/marker-pin.svg';

import Applicants from '../Assets/Images/user.svg';
import Image1 from '../Assets/Images/image1.png';
import Image2 from '../Assets/Images/image2.png';
import Image3 from '../Assets/Images/resume.png';
import LogoGP from '../Assets/Images/gp.png';
import LogoAP from '../Assets/Images/ap.png';

// import Components
import Navigation from "../Components/Nav";
import Footer from '../Components/Footer';
import Loading from '../Components/Loading';
import { ModalAccount, ModalNotAccount } from '../Components/Modal';

import { URL_API, URL_BASE } from '../context/Link';
import { Tanggal, Jam_menit, Rupiah } from '../context/Helper';


function Detail(){
    const [isLoading, setIsloading] = useState(true)
    const [errMsg, setErrMsg] = useState('');
    const [isError, setIsError] = useState(false);
    
    const { id } = useParams();
    // const [kode, setKode] = useState('')
    const [judul, setJudul] = useState('');
    const [konten, setKonten] = useState('');
    const [gambar, setGambar] = useState('');
    const [pelamar, setPelamar] = useState(0);
    const [pekerjaan, setPekerjaan] = useState('');
    const [taskProvinsi, setTaskProvinsi] = useState('');
    const [taskKota, setTaskKota] = useState('');
    const [taskMulai, setTaskMulai] = useState('');
    const [taskSelesai, setTaskSelesai] = useState('');
    const [taskHarga, setTaskHarga] = useState('');
    const [taskWaktu, setTaskWaktu] = useState('');
    const [taskDeskripsi, setTaskDeskripsi] = useState('');
    const [showAccount, setShowAccount] = useState(false);
    const [showNotAccount, setShowNotAccount] = useState(false);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsloading(true);
        getDetailData();
        console.log(id)
        // console.log(destinasi.length)

    }, [])
    
    const getDetailData = async() => {
        setIsloading(true);
        setIsError(false);
        setErrMsg('');
        // console.log('Get Hotel Detail')
        await axios
        .get(`${URL_API}/vacancy/api/detail/`+id)
        .then(res => {
            var respon = res.data.data;
            console.log(respon)
            // setJudul(respon.judul)
            setKonten(respon.isi)
            setGambar(respon.gambar)
            
            setTaskProvinsi(respon.task_provinsi)
            setTaskKota(respon.task_kota)
            setTaskMulai(respon.task_mulai)
            setTaskSelesai(respon.task_selesai)
            setTaskHarga(respon.task_harga)
            setTaskWaktu(respon.task_waktu)
            setTaskDeskripsi(respon.task_deskripsi)
            
            setPekerjaan(respon.nama_pekerjaan)
            setIsloading(false);
        })
        .catch(e => {
            console.log(e)
            setIsloading(false); 
        })
    }


    return(
        <div className="mainBackground">
            {isLoading ? 
            <Loading></Loading>
            :
            null
            }
            <Helmet>
                <meta 
                    charSet="utf-8"
                    name={'Job Vacancy '+pekerjaan+' | IOWork Indonesia'}
                    content={'Job Vacancy '+pekerjaan+'} | IOWork Indonesia'}
                 />
                <title>Job Vacancy {pekerjaan} | IOWork Indonesia</title>
                <link rel="IOWork" href="%PUBLIC_URL%/logo.png"  />
            </Helmet>
            <Navigation></Navigation>
            <div className="bannerImage">
                <img src={gambar} />
            </div>
            <div className="containerVacancy">
                {/* <h2>{judul}</h2> */}
                <div className="detail">
                    <h1>{pekerjaan}</h1>
                    <div className="description">
                        <h3>Detail Pekerjaan</h3>
                        <div className="workDetail">
                            <div className="rowContent">
                                <div className="boxContent">
                                    <img src={IconCalendar} alt="Icon Calendar" />
                                    <div className="text">
                                        <div className="title">Tanggal</div>
                                        <div className="textContent">{Tanggal(taskMulai)}</div>
                                    </div>
                                </div>
                                <div className="boxContent">
                                    <img src={IconClock} alt="Icon Clock" />
                                    <div className="text">
                                        <div className="title">Waktu</div>
                                        <div className="textContent">{Jam_menit(taskMulai)} - {Jam_menit(taskSelesai)}</div>
                                    </div>
                                </div>
                                <div className="boxContent">
                                    <img src={IconBankNote} alt="Icon Bank Note" />
                                    <div className="text">
                                        <div className="title">Fee</div>
                                        <div className="textContent">Rp. {Rupiah(taskWaktu * taskHarga)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnContent">
                                <div className="boxContent">
                                    <img src={IconMarker} alt="Icon Marker" />
                                    <div className="text">
                                        <div className="title">Lokasi</div>
                                        <div className="textContent">{taskProvinsi +", "+ taskKota}</div>
                                    </div>
                                </div>
                                <div className="boxContent">
                                    <img src={IconFile} alt="Icon File" />
                                    <div className="text">
                                        <div className="title">Catatan Khusus</div>
                                        <div className="textContent">{taskDeskripsi}</div>
                                    </div>
                                </div>
                            </div>
                            {/* Lokasi : {taskProvinsi +", "+ taskKota}<br></br>
                            Tanggal : {Tanggal(taskMulai)}<br></br>
                            Jam : {Jam_menit(taskMulai)} - {Jam_menit(taskSelesai)}<br></br>
                            Harga : Rp. {Rupiah(taskWaktu * taskHarga)}<br></br>
                            Catatan Khusus : {taskDeskripsi}<br></br> */}
                        </div>
                    </div>
                    <div className="description">
                        <h3>Deskripsi Pekerjaan</h3>
                        <div
                            dangerouslySetInnerHTML={{__html: konten}}
                        />
                    </div><hr /><br />
                    <div className="boxPrimary">
                        <div className="containerBox">
                            <div className="itemBox">
                                    <h4>Apa itu IOWORK?</h4>
                                    <p>IOWORK adalah tempat dimana penyedia pekerjaan dan pencari kerja bertemu secara langsung tanpa batasan. Masing-masing penyedia pekerjaan dan pencari kerja dapat memilih untuk melakukan kemitraan kerja dengan masing-masing pihak. Kami memiliki vacancy atau kerjasama dengan banyak perusahaan yang tersebar di seluruh kota kota besar di indonesia.</p><br />
                                    <a href="https://iowork.id" target="_blank"><button className="buttonBasic btnfilled">Kunjungi Website IOWORK</button></a>
                            </div>
                            <div className="itemBox">
                                <img src={Image1} alt="Apa itu IOWork?" />
                            </div>
                        </div>
                    </div>
                    <div className="boxPrimary">
                        <div className="containerBox">
                        <div className="itemBox">
                                <img src={Image2} alt="Apa itu IOWork?" />
                            </div>
                            <div className="itemBox">
                                <h4>Bagaimana Cara Kerjanya?</h4>
                                <ul className="point">
                                    <p><li>Cara kerja IOWork sama seperti Aplikasi Ojek Online, dimana pencari kerja dapat mencari pekerjaan yang tersedia di dalam aplikasi.</li></p><br />
                                    <p><li>Pekerjaan yang sudah di lamar nantinya akan di review oleh perusahaan, jika perusahaan menerima nya maka anda akan mendapatkan notifikasi langsung di aplikasi anda.</li></p><br />
                                    <p><li>Lakukan absensi checkin dan checkout di aplikasi sebagai indikator anda sudah hadir sesuai waktu yang sudah di tentukan oleh perusahaan. Jika pekerjaan telah selesai perusahaan akan segera memberi anda rating dan ulasan yang secara otomatis upah anda akan masuk ke dalam saldo anda di dalam aplikasi. Anda dapat mencairkan nya kapanpun anda inginkan.</li></p>
                                </ul>  
                            </div>
                        </div>
                    </div>
                    <div className="boxDanger">
                        <div className="itemBox">
                        <h4>INGAT!</h4>
                            <ul className="point">
                                <p><li>Anda wajib hadir ketika perusahaan menerima anda pada suatu pekerjaan.</li></p>
                                <p><li>Lakukan absensi sesuai dengan yang sudah ditentukan oleh perusahaan, Jika anda telat maka upah anda akan berkurang.</li></p>
                                <p><li>Beri service terbaik pada perusahaan agar anda mendapatkan rating yang baik.</li></p>
                                <p><li>Jika rating anda kurang dari 3 kami akan memblokir akun anda.</li></p>
                            </ul>
                        </div>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <div className="titleGroup">
                                    <h4>Download Aplikasi IOWORK</h4>
                                    <h4>Dapatkan Pekerjaanmu Segera</h4>
                                </div>
                                <div className="splitLogoApps">
                                <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={LogoAP} alt="Logo Apple"></img></a>
                                <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={LogoGP} alt="Logo Google"></img></a>
                                </div>
                                <p>Sudahkah Bergabung Dengan IOWORK?</p>
                                <div className="splitButtonAccount">
                                    <button className="buttonBasic btnblank" onClick={(e) => setShowNotAccount(true)}>Belum</button>
                                    <button className="buttonBasic btnfilled" onClick={(e) => setShowAccount(true)}>Sudah</button>
                                </div>
                            </div>
                            <div className="itemBox">
                                <img src={Image3} alt="Apa itu IOWork?" />
                            </div>
                        </div>
                    </div>
                    <div className="buttonPage">
                        <button className="buttonBasic btnfilled1" onClick={() => openInNewTab("ioworkapp://task/"+id)}>Apply Job</button>
                    </div><hr />
                    <div className="boxMain">
                        <h3>Bagikan Pekerjaan Ini Ke</h3>
                        <div className="boxPage">
                            <FacebookShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork #IOWorkIndonesi #Loker'} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconFB} alt="Facebook"/></div>
                                    <div className="text"><h4>Facebook</h4></div>
                                </div>
                            </FacebookShareButton>  
                            <LinkedinShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork #IOWorkIndonesi #Loker'} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconLinkedIn} alt="LinkedIn"/></div>
                                    <div className="text"><h4>LinkedIn</h4></div>
                                </div>
                            </LinkedinShareButton>  
                            <WhatsappShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork#IOWorkIndonesia#Loker#'+pekerjaan} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconWA} alt="Whatsapp"/></div>
                                    <div className="text"><h4>Whatsapp</h4></div>
                                </div>
                            </WhatsappShareButton>  
                            <TelegramShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork#IOWorkIndonesia#Loker#'+pekerjaan} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconTelegram} alt="Telegram"/></div>
                                    <div className="text"><h4>Telegram</h4></div>
                                </div>
                            </TelegramShareButton>  
                            <LineShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork#IOWorkIndonesia#Loker#'+pekerjaan} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconLine} alt="Line"/></div>
                                    <div className="text"><h4>Line</h4></div>
                                </div>
                            </LineShareButton>  
                            <EmailShareButton url={URL_BASE+'/detail/'+id} quotes={'quotes'} hashtag={'#IOWork#IOWorkIndonesia#Loker#'+pekerjaan} >
                                <div className="boxIcon">
                                    <div className="image"><img src={IconEmail} alt="Email"/></div>
                                    <div className="text"><h4>Email</h4></div>
                                </div>
                            </EmailShareButton>  
                        </div>
                    </div>
                    {/* <div className="boxMain">
                        <h3>Jumlah Pelamar Saat Ini</h3>
                        <div className="boxPage">
                            <div className="boxIcon">
                                <div className="image"><img src={Applicants} alt="Applicants"/></div>
                                <div className="text"><h4>{pelamar}</h4></div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer></Footer>
            <ModalAccount show={showAccount} onClose={(e) => setShowAccount(false)} buttonName={"Download IOWORK Sekarang"} sizeBtnClose={"17"}></ModalAccount>
            <ModalNotAccount show={showNotAccount} onClose={(e) => setShowNotAccount(false)} buttonName={"Download IOWORK Sekarang"} sizeBtnClose={"17"}></ModalNotAccount>
        </div>
    );
}

// com.app.iowork

export default Detail;