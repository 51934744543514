import React from 'react';

import { Link } from "react-router-dom";

import Loc from '../Assets/Images/footerloc.svg';
import Mail from '../Assets/Images/footermail.svg';
import Phone from '../Assets/Images/footerphone.svg';

import Gp from '../Assets/Images/gp.png';
import Ap from '../Assets/Images/ap.png';


import Fb from '../Assets/Images/fb.png';
import Ig from '../Assets/Images/ig.png';
import Yt from '../Assets/Images/yt.png';
import Lk from '../Assets/Images/linkid.png';

import LogoWhite from '../Assets/Images/logoWhite.png';

import { URL_WEB } from '../context/Link';

function Footer() {
    return ( 
        <div className="footer">
            <div className="footerTop">
                <img src={LogoWhite}></img>
            </div>
            <div className="footerMid">
                <div className="info">
                    <div className="infoItem">
                        <img src={Loc}></img>
                        <p>Komplek Golden Plaza Fatmawati blok G 33, Jl. RS Fatmawati No.15, Jakarta Selatan 12420</p>
                    </div>
                    <div className="infoItem">
                        <img src={Phone}></img>
                        <p>+62 811.8888.603</p>
                    </div>
                    <div className="infoItem">
                        <img src={Mail}></img>
                        <p>info@megakreasi.com</p>
                    </div>
                </div>
                <div className="link">
                    <Link to="/">Tentang Kami</Link>
                    <a href="https://vacancy.iowork.id" target="_blank">Lowongan Pekerjaan</a>
                    <a href="https://help.iowork.id" target="_blank">Bantuan</a>
                    {/* <Link to="/">Partnership</Link>
                    <Link to="/">Testimoni</Link>
                    <a href="https://help.iowork.id" target="_blank">FAQ</a> */}
                </div>
                <div className="link">
                    <a href="#">Blog</a>
                    <a href={URL_WEB+"/panduan pekerja.pdf"} target="_blank">Panduan Pekerja</a>
                    <a href={URL_WEB+"/panduan perusahaan.pdf"} target="_blank">Panduan Perusahaan</a>
                    <a href={URL_WEB+"/privacy"} target="_blank" >Kebijakan Privasi</a>
                    <a href={URL_WEB+"/term"} target="_blank">Syarat dan Ketentuan</a>
                </div>
                <div className="download">
                    <h4>Download Aplikasi</h4>
                    <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={Ap}></img></a>
                    <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={Gp}></img></a>
                </div>
            </div>
            <div className="footerBottom">
                <p>All Right Reserved - PT Mega Kreasi Teknologi, 2022</p>
                <div className="linkSocial">
                    <p>Follow us</p>
                    <a href="https://www.facebook.com/iowork.2.0" target="_blank"><img src={Fb}></img></a>
                    <a href="https://www.instagram.com/iowork.indonesia" target="_blank"><img src={Ig}></img></a>
                    <a href="https://www.youtube.com/channel/UCa6dvhFsuiZfN_Q2eF6xLLw" target="_blank"><img src={Yt}></img></a>
                    <a href="https://www.linkedin.com/company/iowork-indonesia/about" target="_blank"><img src={Lk}></img></a>
                </div>
            </div>
        </div>
     );
}

export default Footer;