
//----- PRODUCTION --------------------------------------------------
export const URL_BASE = 'https://vacancy.iowork.id';
export const URL_API = 'https://office.iowork.id';
export const URL_WEB = 'https://iowork.id';
export const URL_UPLOAD = 'https://apiv2.iowork.id';

//----- DEVELOPMENT --------------------------------------------------
// export const URL_BASE = 'https://jobvacancy-iowork.megakreasiteknologi.com';
// export const URL_API = 'https://office-iowork.megakreasiteknologi.com';
// export const URL_WEB = 'https://web-iowork.megakreasiteknologi.com';
// export const URL_UPLOAD = 'https://apiv2-iowork.megakreasiteknologi.com';


export const URL_CDN_IMAGE = 'https://inntouch.sgp1.digitaloceanspaces.com/image/';
export const URL_IMAGE_NONE = 'https://iowork.sgp1.digitaloceanspaces.com/image/blank_photo.png';