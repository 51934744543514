import React from "react";

// import assets
import Mark from '../Assets/Images/mark.svg';
import Search from '../Assets/Images/search.svg';


function SearchBox(props){
    console.log(props)
    return(
        <div className="searchBox">
            <div className="text">
                <h4>Cari Pekerjaan Berdasarkan Daerah, Kota, dan Perusahaan</h4>  
            </div>
            <div className="innerBox">
                <div className="inputPage">
                    <input placeholder="Daerah, Kota, Perusahaan..." onChange={(e) => {
                            props.setText(e.target.value)
                        }}/>
                    <img src={Mark} alt="Mark" />
                </div>
                <div className="buttonSearch">
                    <button onClick={()=>props.cari()}>Cari</button>
                    <img src={Search} />
                </div>
            </div>
        </div>
    );
}

export default SearchBox;