import React from "react";
// import {CardInvoice} from "./Card";
// import Doku from '../assets/doku.png';
// import ButtonClose from '../assets/close.svg';
import ImgAcc1 from '../Assets/Images/imgacc1.png';
import ImgAcc2 from '../Assets/Images/imgacc2.png';
import ImgAcc3 from '../Assets/Images/imgacc3.png';
import ImgAcc4 from '../Assets/Images/imgacc4.png';
import ImgAcc5 from '../Assets/Images/imgacc5.png';
import ImgAcc6 from '../Assets/Images/imgacc6.png';
import ImgAcc7 from '../Assets/Images/imgacc7.png';
import ImgAcc8 from '../Assets/Images/imgacc8.png';
import LogoGP from '../Assets/Images/gp.png';
import LogoAP from '../Assets/Images/ap.png';
import BtnClose from '../Assets/Images/close.png';


export const Modal = (props) => {

    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modal-content-sm">
                <div className="modal-body">
                        <div className='logo'>
                            <img src={props.logo} width={props.sizeLogo}/>
                        </div>
                        <div className='textTitle'>
                            <h2>{props.title}</h2>
                        </div>
                        <div className="content center"><h5>{props.content}</h5></div>
                </div>
                <div className="modal-footer">
                    <button className="buttonBasic" onClick={props.onClose}>{props.buttonName}</button>
                </div>
            </div>
        </div>
    );
}

export const ModalAccount = (props) => {
    // alert(props.onClose);
    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modal-content-lg">
                <div className="modal-header">
                    <div className="buttonClose">
                        <img src={BtnClose} alt="Close" width={props.sizeBtnClose} onClick={props.onClose}/>
                    </div>
                </div>
                <div className="modal-body">
                        <div className="titlePopup">
                            <h5>1. Download Aplikasi IOWORK</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc1} alt="Download App" />
                                </div>
                                <div className="itemBox">
                                    <h5>Download Aplikasi IOWORK</h5>
                                    <h5>Di Google Play dan App Store.</h5>
                                    <div className="logoApps">
                                        {/* <img src={LogoGP} alt="Logo Google" />
                                        <img src={LogoAP} alt="Logo Apple" /> */}
                                        <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={LogoAP} alt="Logo Apple"></img></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={LogoGP} alt="Logo Google"></img></a>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="titlePopup">
                            <h5>2. Login</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc2} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Masuk ke aplikasi IOWORK, dan anda bisa langsung mulai mencari pekerjaan.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="titlePopup">
                            <h5>3. Pastikan Data Diri Anda Sudah Lengkap</h5>
                        </div>
                        <div className="textPopup">
                            <h5>Lengkapi semua data diri anda dari mulai foto profile, nama lengkap, alamat hingga akun bank transfer.</h5>
                        </div>
                        <div className="titlePopup">
                            <h5>4. Tunggu Verifikasi Admin</h5>
                        </div>
                        <div className="textPopup">
                            <h5>Setelah mendaftarkan diri, akun anda akan di verifikasi terlebih dahulu
                            oleh admin (screening) dalam kurun waktu 1-3 hari.</h5>
                        </div>
                        <div className="titlePopup">
                            <h5>5. Join Pekerjaan</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc4} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Cari pekerjaan di menu task dan apply pekerjaan sesuai keahlian dan skillmu.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="titlePopup">
                            <h5>6. Tunggu Approve Oleh Perusahaan</h5>
                        </div>
                        <div className="textPopup">
                            <h5>Setelah apply pekerjaan atau join, anda tinggal menunggu approval
                            dari perusahaan penyedia pekerjaan.</h5>
                        </div>
                        <div className="titlePopup">
                            <h5>7. Mulailah Bekerja</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc5} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Setelah aplikasi  anda di terima oleh perusahan, anda bisa langsung bekerja berdasarkan waktu yang telah di tentukan.</h5>
                                    <h5>Lakukan check in ketika memulai pekerjaan, dan check out ketika pekerjaan telah selesai.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="titlePopup">
                            <h5>8. Selesai Bekerja dan Menunggu Rating</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc6} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Pekerjaan anda akan di review dan di rating oleh perusahaan terkait.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="titlePopup">
                            <h5>9. Mendapatkan Rating dan Menerima Penghasilan</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc7} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Setelah menyelesaikan pekerjaan, dan mendapatkan rating anda akan mendapatkan bayaran.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="titlePopup">
                            <h5>10. Cairkan Saldo</h5>
                        </div>
                        <div className="boxEmpty">
                            <div className="containerBox">
                                <div className="itemBox">
                                    <img src={ImgAcc8} alt="Registrasi" />
                                </div>
                                <div className="itemBox">
                                    <h5>Withdraw penghasilanmu langsung ke rekening bank.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="content center"><h5>{props.content}</h5></div>
                </div><hr />
                <div className="modal-footer">
                    <button className="buttonBasic btnfilled1">{props.buttonName}</button><br />
                    <button className="buttonBasic btnnoborder" onClick={props.onClose}>Kembali</button>
                </div>
            </div>
        </div>
    );
}

export const ModalNotAccount = (props) => {
    // alert(props.onClose);
    if(!props.show){
        return null;
    }
    return (
        <div className="modal">
            <div className="modal-content-lg">
                <div className="modal-header">
                    <div className="buttonClose">
                        <img src={BtnClose} alt="Close" width={props.sizeBtnClose} onClick={props.onClose}/>
                    </div>
                </div>
                <div className="modal-body">
                    {/* <div className='logo'>
                        <img src={props.logo} width={props.sizeLogo}/>
                    </div>
                    <div className='textTitle'>
                        <h2>{props.title}</h2>
                    </div> */}
                    <div className="titlePopup">
                        <h5>1. Download Aplikasi IOWORK</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc1} alt="Download App" />
                            </div>
                            <div className="itemBox">
                                <h5>Download Aplikasi IOWORK</h5>
                                <h5>Di Google Play dan App Store.</h5>
                                <div className="logoApps">
                                    {/* <img src={LogoGP} alt="Logo Google" />
                                    <img src={LogoAP} alt="Logo Apple" /> */}
                                    <a href="https://apps.apple.com/us/app/iowork/id1637939995" target="_blank"><img src={LogoAP} alt="Logo Apple"></img></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.app.iowork" target="_blank"><img src={LogoGP} alt="Logo Google"></img></a>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="titlePopup">
                        <h5>2. Registrasi</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc2} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Masuk ke aplikasi IOWORK, buat akun dan daftarkan data diri anda.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>3. Verifikasi Email</h5>
                    </div>
                    <div className="textPopup">
                        <h5>Setelah membuat akun baru, anda akan menerima email verifikasi untuk memvalidasi keaslian akun dan data diri anda.</h5>
                    </div>
                    <div className="titlePopup">
                        <h5>4. Pastikan Data Diri Anda Sudah Lengkap</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc3} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Lengkapi semua data diri anda dari mulai foto profile, 
                                nama lengkap, alamat hingga akun bank transfer.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>5. Tunggu Verifikasi Admin</h5>
                    </div>
                    <div className="textPopup">
                        <h5>Setelah mendaftarkan diri, akun anda akan di verifikasi terlebih dahulu
                        oleh admin (screening) dalam kurun waktu 1-3 hari.</h5>
                    </div>
                    <div className="titlePopup">
                        <h5>6. Join Pekerjaan</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc4} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Cari pekerjaan di menu task
                                dan apply pekerjaan sesuai keahlian dan skillmu.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>7. Tunggu Approve Oleh Perusahaan</h5>
                    </div>
                    <div className="textPopup">
                        <h5>Setelah apply pekerjaan atau join, anda tinggal menunggu approval
                        dari perusahaan penyedia pekerjaan.</h5>
                    </div>
                    <div className="titlePopup">
                        <h5>8. Mulailah Bekerja</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc5} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Setelah aplikasi  anda di terima oleh perusahan, anda bisa langsung bekerja berdasarkan waktu yang telah di tentukan.</h5>
                                <h5>Lakukan check in ketika memulai pekerjaan, dan check out ketika pekerjaan telah selesai.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>9. Selesai Bekerja dan Menunggu Rating</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc6} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Pekerjaan anda akan di review dan di rating oleh perusahaan terkait.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>10. Mendapatkan Rating dan Menerima Penghasilan</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc7} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Setelah menyelesaikan pekerjaan, dan mendapatkan rating anda akan mendapatkan bayaran.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="titlePopup">
                        <h5>11. Cairkan Saldo</h5>
                    </div>
                    <div className="boxEmpty">
                        <div className="containerBox">
                            <div className="itemBox">
                                <img src={ImgAcc8} alt="Registrasi" />
                            </div>
                            <div className="itemBox">
                                <h5>Withdraw penghasilanmu langsung ke rekening bank.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="content center"><h5>{props.content}</h5></div>
                </div><hr />
                <div className="modal-footer">
                    <button className="buttonBasic btnfilled1">{props.buttonName}</button><br />
                    <button className="buttonBasic btnnoborder" onClick={props.onClose}>Kembali</button>
                </div>
            </div>
        </div>
    );
}