// import React from 'react';
// import { createContext, useEffect, useState } from 'react';

import moment from 'moment';

export function  Tanggal_backend (date) {
    // var tgl = Date.parse(props.date)
    var d = new Date(date),
        second = '' + d.getSeconds(),
        minute = '' + d.getMinutes(),
        hour = '' + d.getHours(),
        day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return year+'-'+month+'-'+ day;
}

export function  Tanggal_frontend (date) {
    const bulan = ["Jan","Feb","Mar","Apr","Mei","Jun","Jul","Agus","Sep","Okt","Nov","Des"];
    // var tgl = Date.parse(props.date)
    var d = new Date(date),
        second = '' + d.getSeconds(),
        minute = '' + d.getMinutes(),
        hour = '' + d.getHours(),
        day = '' + d.getDate(),
        month = '' + (d.getMonth()),
        year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return day+' '+bulan[month]+' '+ year;
}
export function  Tanggal (date) {
    const bulan = ["Jan","Feb","Mar","Apr","Mei","Jun","Jul","Agus","Sep","Okt","Nov","Des"];
    // var tgl = Date.parse(props.date)
    var d = new Date(date),
        second = '' + d.getSeconds(),
        minute = '' + d.getMinutes(),
        hour = '' + d.getHours(),
        day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return day+' '+bulan[month-1]+' '+ year;
}

export function  Jam_menit (date) {
    var d = moment.utc(date).toDate();
    var dateLocal = moment.utc(d).local().format('HH:mm');
    return dateLocal;
    // var d = new Date(date);
        // second = '' + d.getSeconds(),
        // minute = '' + d.getMinutes(),
        // hour = '' + d.getHours(),
        // day = '' + d.getDate(),
        // month = '' + (d.getMonth() + 1);
        // year = d.getFullYear();

    // var offset = new Date().getTimezoneOffset();
    // var d_local = new Date (d.getTime() + offset * 60 * 1000);
    // d_local = moment(d_local).format('HH:mm').toString();
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // if (hour.length < 2) hour = '0' + hour;
    // if (minute.length < 2) minute = '0' + minute;
    // if (second.length < 2) second = '0' + second;

    // return hour+':'+minute;
}

export function  Rupiah (number) {
    // console.log(number)
    number = (number>0 ? parseInt(number) : 0)
    return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    // return 0
}

export function Jarak (number) {
    number = (number>0 ? parseFloat(number) : 0)
    return number.toFixed(2)
}

export function CekJarak (LatPoin, LonPoin, LatUser, LonUser, rad) {
    var lokasi = { latitude: LatPoin, longitude:  LonPoin }
    var startPoint = { latitude: LatUser, longitude: LonUser}


    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * lokasi.latitude / 180.0) * ky;
    var dx = Math.abs(lokasi.longitude - startPoint.longitude) * kx;
    var dy = Math.abs(lokasi.latitude - startPoint.latitude) * ky;
    var hasil = Math.sqrt(dx * dx + dy * dy)*1000;
    console.log(hasil)
    if(hasil>rad){
        return false
    }else{
        return true
    }
}

export function firstUpper (word) {
    var str = word;
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    // str.replace(/\b./g, function(m){ return m.toUpperCase(); });
    // console.log(str)
}

export async function calculateDateInDay (tgl1, tgl2) {
    var date1 = new Date(tgl1);
    var date2 = new Date(tgl2);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days
}
