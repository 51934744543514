import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

//import asset
import CardVacancy from '../Components/Card';
import Loading from '../Components/Loading';

//import component
import Banner from '../Components/Banner';
import SearchBox from '../Components/SearchBox';
import Navigation from '../Components/Nav';
import Footer from '../Components/Footer';

import { URL_API, URL_IMAGE_NONE } from '../context/Link';

function Testing() {
    const [isLoading, setIsLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const[listPekerjaan, setListPekerjaan] = useState([]);
    const[buttonMore, setButtonMore] = useState(true);
    const[limit, setLimit] = useState(12);
    const[page, setPage] = useState(0);

    const { id } = useParams();

    useEffect(() => {
        setListPekerjaan([])
        console.log(id);
        setIsLoading(true);
        cekResetData();
    }, [])

    const cekResetData = async() => {
        setIsLoading(true);
        setIsError(false);
        setErrMsg('');

        const formData = new FormData();
        formData.append('search', '');
        formData.append('page',page); 
        formData.append('limit',limit);

        await axios
        .post(`${URL_API}/vacancy/api/list`,formData)
        .then(res => {
            console.log(res.data)
            var listData = res.data.data
            for (let i = 0; i < listData.length; i++) {
                const e = listData[i];
                setListPekerjaan((prevArr) => ([...prevArr, e]));
            }
            if (res.data.jumlah < limit){
                setButtonMore(false)
            }
            if (res.data.status == 200){
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
            } 
            console.log(page)
            setPage(page+1)
        })
        .catch(e => {
            console.log(e.response.data)
            if(e.response.data.status_code == 404){
                //KALO ERROR KASIH PESAN INI
                console.log(e.response.data.data)
                setIsLoading(false);
                setErrMsg(e.response.data.msg);
            }else{
                console.log(e.response.data.errors)
                setIsLoading(false);
                setErrMsg(e.response.data.errors);
                // console.log(e.message)
            }
        })
    }


    return ( 
        <div className="mainBackground">
            {isLoading ? 
            <Loading></Loading>
            :
            null
            }
            <Navigation></Navigation>
            <Banner></Banner>
            <div className="containerVacancy">
                
                <a href="ioworkapp://task/650afccb-72cb-4e1d-86e3-83cc2d185e61">Launch Apps</a>
                
            </div>
            <Footer></Footer>
        </div>
     );
}

export default Testing;