import React, { Component, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
// import Assets
// import Loker from '../Assets/Images/loker.png';
import Logo from '../Assets/Images/resume.png';
import Failed from '../Assets/failed.gif';

// import Components
import Navigation from "../Components/Nav";
import Footer from '../Components/Footer';
import { Modal } from '../Components/Modal';
import Loading from '../Components/Loading';

import { URL_API, URL_UPLOAD } from '../context/Link';


function ApplyJob(){
    const [isLoading, setIsloading] = useState(true)
    const [errMsg, setErrMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const recaptchaRef = React.createRef();
    
    const { id } = useParams();
    const navigate = useNavigate();
    const[show, setShow] = useState(false);
    const [jobID, setJobID] = useState('')
    const [kode, setKode] = useState('')
    const [pekerjaan, setPekerjaan] = useState('')
    const [nama, setNama] = useState('')
    const [telp, setTelp] = useState('')
    const [email, setEmail] = useState('')
    const [alamat, setAlamat] = useState('')
    const [deskripsi, setDeskripsi] = useState('')
    const [pengalaman, setPengalaman] = useState(1)
    const [cv, setCv] = useState([])
    const [cvText, setCvText] = useState('Upload your CV')
    const [CAPTCHA, setCAPTCHA] = useState('')
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsloading(true);
        getDetailData();
        console.log(id)
    }, [])
    
    const getDetailData = async() => {
        setIsloading(true);
        setIsError(false);
        setErrMsg('');
        
        await axios
        .get(`${URL_API}/vacancy/api/detail/`+id)
        .then(res => {
            var respon = res.data.data;
            console.log(respon)
            setKode(respon.kode)
            setPekerjaan(respon.pekerjaan)
            setJobID(respon.id)
            setIsloading(false);
        })
        .catch(e => {
            console.log(e)
            setIsloading(false); 
        })
    }
    
    const kirimLamaran = async() => {
        if(nama==''){
            setIsError(true);
            setErrMsg('Nama tidak boleh kosong');
            return false
        }
        if(email==''){
            setIsError(true);
            setErrMsg('Email tidak boleh kosong');
            return false
        }
        if(telp==''){
            setIsError(true);
            setErrMsg('Telp tidak boleh kosong');
            return false
        }
        if(alamat==''){
            setIsError(true);
            setErrMsg('Alamat tidak boleh kosong');
            return false
        }
        if(deskripsi==''){
            setIsError(true);
            setErrMsg('Deskripsi tidak boleh kosong');
            return false
        }
        if(pengalaman==''){
            setIsError(true);
            setErrMsg('Pengalaman tidak boleh kosong');
            return false
        }
        if(cv==''){
            setIsError(true);
            setErrMsg('Anda harus mengupload CV ');
            return false
        }
        if(CAPTCHA==''){
            setIsError(true);
            setErrMsg('CAPTCHA Invalid ');
            return false
        }

        setIsloading(true);
        setIsError(false);
        setErrMsg('');
        
        const formData = new FormData();
        formData.append('id', jobID);
        formData.append('nama',nama);
        formData.append('email',email);
        formData.append('telp',telp);
        formData.append('alamat',alamat);
        formData.append('deskripsi',deskripsi);
        formData.append('pengalaman',pengalaman);
        formData.append('cv',cv);
        formData.append('captcha',CAPTCHA);

        await axios
        .post(`${URL_API}/vacancy/api/kirim`, formData)
        .then(res => {
            console.log(res.data)
            if (res.data.status == 200){
                setTimeout(() => {
                    setIsloading(false);
                    setShow(true);
                }, 1000)
            }
        })
        .catch(e => {
            console.log(e.response.data)
            if(e.response.data.status == 404){
                //KALO ERROR KASIH PESAN INI
                console.log(e.response.data.data)
                setIsloading(false);
                setIsError(true);
                setErrMsg(e.response.data.msg);
            }else{
                console.log(e.response.data.errors)
                setIsloading(false);
                setIsError(true);
                setErrMsg(e.response.data.errors);
                // console.log(e.message)
            }
        })
    }
    
    const upload = async(val) => {
        setCvText(val.value)
        setIsloading(true);
        setIsError(false);
        setErrMsg('');
        
        const data = new FormData();
        data.append('profile_pic', val.files[0]);
        data.append('folder','vacancy/cv');
        
        await axios
        .post(`${URL_UPLOAD}/dashboard/upload`, data)
        .then(res => {
            var respon = res.data.data;
            setCv(respon.filename)
            console.log(res)
            setIsloading(false);
        })
        .catch(e => {
            console.log(e)
            setIsloading(false); 
        })
    }

    const onChange = async(value) => {
        setCAPTCHA(value);
        console.log("Captcha value:", value);

        //sendi added 03-Jan-2023
        const data = new FormData();
        data.append('captcha', value);

        await axios
        .post(`${URL_API}/vacancy/api/insertCaptcha`, data)
        .then(res => {
            console.log(res.data);
            setIsloading(false);
        })
        .catch(e => {
            console.log(e);
            setIsloading(false);
        })
    }
    const onSubmit = async(value) => {
    // onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
        console.log('captcha: ', recaptchaValue);
    }


    return(
        <div className="mainBackground">
            {isLoading ? 
            <Loading></Loading>
            :
            null
            }
            <Navigation></Navigation>
            <div className="containerVacancy">
                <h2>Isi Form Apply Job</h2>
                <div className="split">
                <div className="formGroup">
                    <label>Kode Pekerjaan</label>
                    <input type="text" readOnly value={kode} />
                </div>
                <div className="formGroup">
                    <label>Jenis Pekerjaan</label>
                    <input type="text" readOnly value={pekerjaan} />
                </div>
                </div>
                <div className="formGroup">
                    <label>Nama Lengkap</label>
                    <input type="text" placeholder="Type something here..." value={nama} onChange={(e) => setNama(e.target.value)}/>
                </div>
                <div className="formGroup">
                    <label>Email</label>
                    <input type="text" placeholder="Type something here..." value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="formGroup">
                    <label>No. Telepon</label>
                    <PhoneInput international
                    defaultCountry="ID"
                    value={telp}
                    onChange={setTelp}/>
                </div>
                <div className="formGroup">
                    <label>Alamat Lengkap</label>
                    <textarea placeholder="Type something here..." value={alamat} onChange={(e) => setAlamat(e.target.value)}></textarea>
                </div>
                <div className="formGroup">
                    <label>Deskripsikan Dirimu</label>
                    <textarea placeholder="Type something here..." value={deskripsi} onChange={(e) => setDeskripsi(e.target.value)}></textarea>
                </div>
                <div className="formGroup">
                    <label>Pengalaman Kerja</label>
                    <select value={pengalaman} onChange={(e) => setPengalaman(e.target.value)}>
                        <option value="<1">&lt; 1 Tahun</option>
                        <option value="1">1 Tahun</option>
                        <option value=">1">&gt; 1 Tahun</option>
                        <option value=">5">&gt; 5 Tahun</option>
                        <option value=">10">&gt; 10 Tahun</option>
                    </select>
                </div>
                <div className="upload-btn-wrapper">
                    <div className="text">{cvText}</div>
                    <button className="btn">Pilih File</button>
                    <input type="file" name="myfile" accept='.doc,.docx,application/pdf' onChange={(e) => upload(e.target)}/>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <form onSubmit={onSubmit} >
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcT1b0jAAAAANVMsoDcdT_7PnPzxE8ouYgZvZpZ"
                        onChange={onChange}
                    />
                </form>
                <div className="buttonPage">
                    <button className="btnblank" onClick={() => navigate(-1)}>Kembali</button>
                    <button className="btnfilled" onClick={() => kirimLamaran()}>Kirim</button>
                </div>
            </div>
            <Footer></Footer>
            <Modal 
                show={show} 
                logo={Logo} 
                sizeLogo={170} 
                title={"Resume Berhasil Dikirim"} 
                content={"Lamaran anda untuk posisi "+pekerjaan+" telah kami terima, selanjutnya Tim kami akan melakukan verifikasi terlebih dahulu. Jika kamu lulus kualifikasi tim IOWork akan segera menghubungi kamu.."} 
                buttonName={"Kembali"} 
                onClose={() => {
                    setShow(false)
                    navigate('/')
                }}
            />
            <Modal show={isError} logo={Failed} sizeLogo={170} title={"Gagal"} content={errMsg} buttonName={"Kembali"} onClose={() => setIsError(false)}/>
        </div>
    );
}

export default ApplyJob;