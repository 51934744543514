import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import Search from './Pages/Search';
import Detail from './Pages/Detail';
import ApplyJob from './Pages/ApplyJob';
import Testing from './Pages/Testing';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/search" element={<Search />}></Route>
      <Route path="/detail/:id" element={<Detail />}></Route>
      <Route path="/applyjob/:id" element={<ApplyJob />}></Route>
      <Route path="/Testing" element={<Testing />}></Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
