import React, { Component } from 'react';
import LoadingGif from '../Assets/loading_io.gif'

function Loafing() {
    return ( 
        <div className="loading">
            <img src={LoadingGif}></img>
            {/* <div>Tunggu</div> */}
        </div>
     );
}

export default Loafing;