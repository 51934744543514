import React from "react";
import { Link } from "react-router-dom";
import ImgVaccancy from '../Assets/Images/vac1.png';
import Map from '../Assets/Images/map.svg';

import { Tanggal_frontend } from '../context/Helper';

function CardVacancy(props){
    return(
        <div className="cardVacancy">
            <img src={props.gambar} alt="Card"/>
            <div className="text">
            <h4>{Tanggal_frontend(props.tgl_buat)}</h4>
            <h3>{props.pekerjaan}</h3>
            <div className="location">
                <img src={Map} alt="Map"/>
                <div className="text">
                    <h4>{props.provinsi} - {props.kota}</h4>
                </div>
            </div>
            <Link to={"/detail/"+props.id}>
                <button>Lihat Detail</button>
            </Link>
            </div>
        </div>
    );
}

export default CardVacancy;