import React from "react";
import BannerImage from '../Assets/Images/man.png';

function Banner(){
    return(
        <div className="banner">
            <div className="content">
                <h3>Temukan Pekerjaan <br/>Dengan IOWork</h3>
                {/* <p>IOWORK adalah tempat dimana penyedia pekerjaan dan pencari kerja bertemu secara langsung tanpa batasan. Masing-masing penyedia pekerjaan dan pencari kerja dapat memilih untuk melakukan  kemitraan kerja dengan masing-masing pihak.</p> */}
            </div>
            <div className="bannerImage">
                <img src={BannerImage}></img>
            </div>
        </div>
    );
}

export default Banner;